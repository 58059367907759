'use client';

import {useRef, useState} from 'react';
import Image from 'next/image';
import {usePathname, useRouter} from 'next/navigation';
import {Product} from '@delorand/db/schema/product';
import {searchCollectS, SToType} from '@delorand/schema/dto/shared';
import {ConvertDateToString} from '@delorand/schema/entities/shared';
import {debounce} from '@delorand/utils/lod';
import {formatCurrency, getThumbnail} from '@delorand/utils/shared';
import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {useStore} from 'c/store/store';
import {client} from 'c/utils/api';
import {X} from 'lucide-react';
import {RemoveScroll} from 'react-remove-scroll';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import {fetchApi} from '../utils/misc';

const Search = () => {
  const open = useStore(state => state.searchOpen);

  if (open) return <SearchComponent />;
};

const SearchComponent = () => {
  const router = useRouter();
  const pathname = usePathname();
  const inputRef = useRef<HTMLInputElement>(null);

  const closeSearch = useStore(state => state.closeSearch);

  const [{hits, search}, setSearchData] = useState<{
    hits: Omit<ConvertDateToString<Product>, 'variants'>[];
    search: string;
  }>({hits: [], search: ''});

  const handleSearch = debounce(async (value: string) => {
    if (value.length > 1) {
      try {
        setSearchData(
          (await fetchApi(client.product.search.$get({q: value}))).data ?? {
            hits: [],
            search: value,
          }
        );
      } catch (err) {
        setSearchData(state => ({
          hits: state.hits,
          search: value,
        }));
        console.log(err);
      }
    } else
      setSearchData({
        hits: [],
        search: value,
      });
  }, 600);

  const {data: explore} = useSWR(['explore'], () =>
    fetchApi(client.product.explore.$get())
  );

  const {data: searchHistory} = useSWR(
    ['search.history'],
    async () => (await fetchApi(client.customer['search-history'].$get())).data
  );

  const {trigger: collect} = useSWRMutation(
    ['search.collect'],
    async (_, {arg}: {arg: SToType<typeof searchCollectS>}) =>
      (await fetchApi(client.customer['search-analytics'].$post({json: arg})))
        .data
  );

  const [isFocused, setFocused] = useState(false);

  return (
    <>
      <RemoveScroll className="fixed inset-0 z-50 w-full max-w-md bg-black">
        <div className="mt-[1%] flex h-[8%] w-full items-center gap-2 bg-black pr-3 pl-1">
          <button className="text-white" aria-label="" onClick={closeSearch}>
            <ChevronLeftIcon width={30} className="md:hidden" />
            <XMarkIcon width={30} className="hidden md:block" />
          </button>
          <div className="relative w-full rounded-[10px] bg-white/15 transition-all">
            <input
              ref={inputRef}
              className="w-full bg-transparent py-1 pr-10 pl-4 text-lg transition-all placeholder:text-white/30"
              placeholder="search in products, brands, posts..."
              onChange={e => handleSearch(e.target.value)}
              onFocus={() => setFocused(true)}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (!e.target.value) return;
                  collect({
                    hint: e.target.value,
                  });
                  if (pathname === '/search')
                    router.replace(
                      `/search?search=${search || e.target.value}`
                    );
                  else
                    router.push(`/search?search=${search || e.target.value}`);
                  closeSearch();
                  // e.target.value = '';
                }
              }}
            />
            <button
              className="absolute top-1/2 right-0 mr-3 inline-flex h-4 w-4 -translate-y-1/2 items-center justify-center"
              aria-label="cancel-search"
              onClick={() => {
                if (inputRef.current) inputRef.current.value = '';
                handleSearch('');
              }}
            >
              <X className="fill-muted size-5" />
            </button>
          </div>
          {(inputRef?.current?.value?.length ?? 0) > 0 && (
            <button
              aria-label="search"
              className="mx-2 text-white"
              onClick={() => {
                if (
                  !inputRef?.current?.value &&
                  inputRef?.current?.value !== null
                )
                  return;
                collect({
                  hint: inputRef.current.value,
                });
                if (pathname === '/search') {
                  router.replace(
                    `/search?search=${search || inputRef.current.value}`
                  );
                } else
                  router.push(
                    `/search?search=${search || inputRef.current.value}`
                  );
                closeSearch();
              }}
            >
              <MagnifyingGlassIcon width={25} />
            </button>
          )}
          {isFocused && (
            <button
              aria-label="search"
              className="text-custom-white"
              onClick={() => {
                setFocused(false);
              }}
            >
              cancel
            </button>
          )}
        </div>
        {/* <Divider /> */}

        <div className="h-[92%] overflow-y-auto">
          {!isFocused ? (
            <div className="grid grid-cols-3 gap-1">
              {explore?.data?.map(item => (
                <button
                  key={item.id}
                  onClick={() => {
                    router.push(`/item/${item.id}`);
                    closeSearch();
                  }}
                >
                  <Image
                    alt={`product-${item.id}`}
                    src={getThumbnail(item.images)}
                    width={300}
                    height={300}
                  />
                </button>
              ))}
            </div>
          ) : (
            <ul className="h-full overflow-y-auto">
              {hits.length < 1 ? (
                <>
                  <p className="text-custom-neutral mb-3 ml-4 text-[12px]">
                    History
                  </p>
                  {searchHistory?.map(history => (
                    <div
                      key={history.id}
                      className="px-10 py-2"
                      onClick={() => {
                        if (pathname === '/search')
                          router.replace(
                            `/search?search=${history.hint}&hit=${history.clickedProduct?.slug ?? ''}`
                          );
                        else
                          router.push(
                            `/search?search=${history.hint}&hit=${history.clickedProduct?.slug ?? ''}`
                          );
                        closeSearch();
                      }}
                    >
                      <p className="text-neutral-400">
                        {history.clickedProduct?.title ?? history.hint}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {hits.map(hit => (
                    <li
                      key={hit.id}
                      onClick={() => {
                        collect({
                          clickedProductId: hit.id,
                          hint: search,
                        });
                        if (pathname === '/search')
                          router.replace(
                            `/search?search=${search}&hit=${hit.slug}`
                          );
                        else
                          router.push(
                            `/search?search=${search}&hit=${hit.slug}`
                          );
                        closeSearch();
                      }}
                      className="flex w-full cursor-pointer px-10 py-2"
                    >
                      <div className="">
                        <Image
                          alt={`product-${hit.title}`}
                          src={getThumbnail(hit.images)}
                          width={100}
                          height={200}
                          className="size-10 rounded-lg"
                        />
                      </div>
                      <div className="w-full pl-7">
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                          {hit.title}
                        </p>
                        <p className="text-[13px] text-neutral-500">
                          {formatCurrency(hit.defaultPrice)}
                        </p>
                      </div>
                    </li>
                  ))}
                </>
              )}
            </ul>
          )}
        </div>
      </RemoveScroll>
    </>
  );
};

export default Search;
