import {MaxShippingHours} from '@delorand/db/constant';
import {Order, StoreOrder} from '@delorand/db/schema/order';
import {Product} from '@delorand/db/schema/product';
import {addHours} from './date';

export const isDev = process.env.NODE_ENV === 'development';

// const mockString = (value?: string) => {
//   if (value !== undefined) return value;

//   if (process.env.NODE_ENV === 'development') {
//     return 'mock test';
//   }
// };

// const mockNumber = (value?: number, defaultValue?: number) => {
//   if (value !== undefined) return value;

//   if (process.env.NODE_ENV === 'development') {
//     return defaultValue || 100;
//   }
// };

export const formatCurrency = (value?: number) =>
  (value || 0)
    .toLocaleString('en', {
      currency: 'NGN',
      style: 'currency',
      compactDisplay: 'short',
    })
    .replace(/\.00/g, '')
    .toString();

export const cleanObject = (object?: {[key: string]: string | undefined}) => {
  if (object)
    Object.keys(object).forEach(key => {
      if (object[key] === null || object[key] === undefined) {
        delete object[key];
      }
    });
  return object;
};

export const filterNulls = <S>(e: S | null): e is Exclude<typeof e, null> =>
  e !== null;
export const filterUndefined = <S>(
  e: S | undefined
): e is Exclude<typeof e, undefined> => e !== undefined;

export const removeUndefined = (obj: {[key: string]: any}) => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const getVariant = (
  variants: Product['variants'],
  variantId?: number | null
) =>
  (variantId
    ? variants.find(x => x.id === variantId)
    : variants.sort((a, b) => a.price - b.price)[0])!;

export const getThumbnail = (
  images: Product['images'],
  variantColor?: string
) => {
  return (
    (images.find(x => x.id === variantColor || x.id === '0') || images?.[0])
      ?.url || ''
  );
};

export const uniqueOrThrow = <T>(value: T | undefined): T => {
  if (!value) throw new Error('Found non unique or inexistent value');
  return value;
};

export const oneOrThrow = <T>(values: T[]): T => {
  const value = values?.[0];
  if (!value) throw new Error('Found non unique or inexistent value');
  return value;
};

export const notNull = <T>(value: T | null | undefined): T => {
  if (!value) throw new Error('Null value');
  return value;
};

export const upperFirst = (value: string | undefined) =>
  typeof value !== 'string'
    ? ''
    : value.charAt(0).toUpperCase() + value.slice(1);

export const formatEnum = (str: string) =>
  upperFirst(str.split('_')[0]!.toLowerCase());

export function ensureArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function safeParseJson<T>(input: string | undefined): T | undefined {
  if (input === undefined) return undefined;
  try {
    return JSON.parse(input);
  } catch (e) {
    return undefined; // Return undefined if parsing fails
  }
}
