import type {ClientResponse} from 'hono/client';

class ApiError extends Error {
  error: any;

  constructor(error: any, msg: string) {
    super(msg);
    this.error = error;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export async function fetchApi<T>(
  call: Promise<ClientResponse<{data?: T; error?: any; msg: string}>>
): Promise<{data: T; msg: string}> {
  try {
    const response = await call;

    if (!response.ok) {
      const errorJson = (await response.json().catch(() => null)) as any;
      const errorMsg = errorJson?.msg || `Something went wrong`;
      throw new ApiError(errorJson?.error || 'Something went wrong', errorMsg);
    }

    const json = await response.json();
    const {data, error, msg} = json as any;

    if (error) {
      throw new ApiError(error, msg);
    }

    return {data, msg};
  } catch (err: unknown) {
    if (err instanceof ApiError) {
      throw err;
    }

    const errorMessage = err instanceof Error ? err.message : 'Request failed';
    throw new ApiError((err as any)?.error ?? 'Unknown error', errorMessage);
  }
}
