export function groupBy<T, K extends keyof any>(
  array: T[],
  fn: (item: T) => K
): Record<K, T[]> {
  return array.reduce(
    (result, item) => {
      const key = fn(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    },
    {} as Record<K, T[]>
  );
}

export function map<T, U>(
  collection: T[] | Record<string, T>,
  iteratee: (item: T, key: string | number) => U
): U[] {
  if (Array.isArray(collection)) {
    return collection.map(iteratee);
  } else {
    return Object.keys(collection).map(key => {
      const value = collection[key];
      if (value !== undefined) {
        return iteratee(value, key);
      }
      throw new Error(`Value at key "${key}" is undefined.`);
    });
  }
}

export function sortBy<T>(array: T[], iteratee: (item: T) => any): T[] {
  return [...array].sort((a, b) => {
    const aValue = iteratee(a);
    const bValue = iteratee(b);

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
}

export function flatten<T>(array: T[][]): T[] {
  return array.reduce(
    (acc, val) => acc.concat(Array.isArray(val) ? val : [val]),
    [] as T[]
  );
}

type FlattenArray<T> = T extends (infer U)[]
  ? U extends (infer V)[]
    ? FlattenArray<V> // Recursively handle nested arrays
    : U
  : T;

export function deepFlatten<T>(array: (T | T[])[]): FlattenArray<T>[] {
  const result: FlattenArray<T>[] = [];

  for (const item of array) {
    if (Array.isArray(item)) {
      result.push(...deepFlatten(item));
    } else {
      result.push(item as FlattenArray<T>);
    }
  }

  return result;
}

export const debounce = <Params extends any[]>(
  func: (...args: Params) => any,
  timeout = 400
) => {
  let timer: any;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export function sumBy<T>(array: T[], fn?: (item: T) => number): number {
  if (fn) {
    return (array as T[]).reduce((sum, item) => sum + fn(item), 0);
  } else {
    return (array as number[]).reduce((sum, item) => sum + item, 0);
  }
}

export function uniq<T>(array: T[]): T[] {
  return array.filter((value, index, self) => self.indexOf(value) === index);
}

export function uniqBy<T, K>(array: T[], iteratee: (item: T) => K): T[] {
  const seen = new Set<K>();
  return array.filter(item => {
    const key = iteratee(item);
    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
}

export function countBy<T>(
  array: T[],
  iteratee: (item: T) => any
): {[key: string]: number} {
  return array.reduce(
    (acc, item) => {
      const key = iteratee(item);
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    },
    {} as {[key: string]: number}
  );
}

export type Iteratee<T> = (item: T) => any;

export function intersectionBy<T>(arrays: T[][], iteratee: Iteratee<T>): T[] {
  if (arrays.length === 0) return []; // Handle empty input

  const firstArray = arrays[0];

  if (!firstArray || firstArray.length === 0) return []; // Handle empty first array

  // Create a set of keys from the first array using the iteratee
  const firstArrayKeys = new Set(firstArray.map(item => iteratee(item)));

  // Compute the intersection
  const intersection = arrays.slice(1).reduce<T[]>((acc, array) => {
    if (!array || array.length === 0) return []; // Handle empty arrays
    const currentArrayKeys = new Set(array.map(item => iteratee(item)));
    return acc.filter(item => currentArrayKeys.has(iteratee(item)));
  }, firstArray);

  return intersection;
}

export function isEmpty(value: any): boolean {
  if (value == null) return true;

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return Object.keys(value).length === 0;
  }

  if (typeof value === 'string') {
    return value.length === 0;
  }

  return false;
}

type Predicate<T> = (value: T, key: string) => boolean;

export function pickBy<T>(
  obj: Record<string, T>,
  predicate: Predicate<T>
): Record<string, T> {
  const result: Record<string, T> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (predicate(value, key)) {
      result[key] = value;
    }
  }

  return result;
}
